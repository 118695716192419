import Http from 'common/services/Http';
import { ExportDataItemSearchCriteria } from './models/ExportDataItemSearchCriteria';
import { ExportDataItemSelectedDto } from './models/ExportDataItemSelectedDto';



class ExportDataItemsService {

    public getItems(criteria: ExportDataItemSearchCriteria) {
        return Http.get<ExportDataItemSelectedDto[]>('exportdataitemcompanies/getItems', criteria);
    }   
    
    public update(model: ExportDataItemSelectedDto[]) {
        console.log(model);
        console.log({ list : model.map(r => { return { id: r.value, active: r.selected }; }) });

        return Http.put('exportdataitemcompanies', { items : model.map(r => { return { id: r.value, active: r.selected }; }) });
    }

}

export default new ExportDataItemsService();
