import styles from './ReportColumnFilterModal.module.scss'

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import Loading from 'common/services/Loading';
import ExportDataItemsService from 'api/exportDataItems/ExportDataItemsService';
import { ExportDataItemSearchCriteria } from 'api/exportDataItems/models/ExportDataItemSearchCriteria';
import { LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import toggleSwitchStyle from 'common/components/toggleSwitch/ToggleSwitch.module.scss'
import { ExportDataItemSelectedDto } from 'api/exportDataItems/models/ExportDataItemSelectedDto';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import CloseButton from 'assets/svg/x-circle.svg'
import Button from 'common/components/button/Button';
import Box from 'common/components/box/Box';

type Props = {
	isOpen: boolean;
	setIsOpen: (status: boolean) => void;
	type: string;
	companyId: string;
	getReport: (type: string) => void;
}

const ReportColumnFilterModal: React.FC<Props> = ({ isOpen, setIsOpen, type, getReport, companyId }: Props) => {
	const { t } = useTranslation();	
    const { addToast } = useToasts();	
	const dispatch = useDispatch();
	const [criteria, setCriteria] = useState<ExportDataItemSearchCriteria>({
        itemsPerPage: 5000,
        page: 1,        
        orderBy: 'asc',
		orderColumn: 'eic.export_data_item_id',
		companyId: companyId,
		type: type           
    });
	const [items, setItems] = useState<ExportDataItemSelectedDto[]>([]);
	const [refresh, setRefresh] = useState(0);

	const closeModal = () => {
		setIsOpen(false)
	}

	const getData = async () => {

        try {
            Loading.show();
            const items = await ExportDataItemsService.getItems(criteria);
			setItems(items);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get export data item list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
        finally {
            Loading.hide();
        }
    }

	
	useEffect(() => {        
        getData();        
    }, [type]);
	
	const onSave = async () => {
		await ExportDataItemsService.update(items);
		getReport(type);
		closeModal();
	}

	const renderLabelColumnCell = (item: ExportDataItemSelectedDto) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>                                                   
                <div>
				{t(`export_data_report.list.types.${item.label}` as any)}
                </div>
            </div>
        )
    }

	const tableColumns: ListingTableColumn<ExportDataItemSelectedDto>[] = [
		{
			name: t('export_data_report.list.label'),			
			searchField: 'v.registration_number',
			renderCell: renderLabelColumnCell,
			width: 'fill'
		},				
		{			
			name: t('export_data_report.list.selected'),
			renderCell: row => <div key={'active_' + row.value}>
				<label
					key={`list_${refresh}`}
					style={{ cursor: 'pointer' }}
					className={`${toggleSwitchStyle.toggleSwitch} ${toggleSwitchStyle.enabled}`}>					
					<input
						type='checkbox'
						id={`checkbox_${row.value}_${row.selected}`}						
						checked={row.selected}
						onChange={e => {
							console.log(row.selected);
							row.selected = !row.selected;
							setRefresh(refresh +1 );
						}}						
					/>
					<span className={toggleSwitchStyle.switch} />
				</label>
			</div>,
			width: 'fill'
		}
	];
	
	return (
		<>
			{isOpen && (
				<>
					<div className={styles.overlay} />
					<div className={styles.modalContainer}>
					<Box>
						<ScreenTitle title={t('export_data_report.title')}>{t('export_data_report.title')}</ScreenTitle>
						<div className={styles.scrollable}>
							<ListingTable columns={tableColumns} rows={items} allowHover={true} />							
						</div>								
						<div className={styles.buttonContainer}>
							<Button type='button' text={t('common.next')} onClick={() => onSave()} />										
						</div>
					<ScreenHeaderButton
						icon={CloseButton}
						onClick={() => closeModal()}
						className={styles.closeButton}
					></ScreenHeaderButton>
					</Box>
					</div>
				</>
			)}
		</>
	)
}

export default ReportColumnFilterModal
