import AccidentsService from 'api/accidents/AccidentsService';
import { AccidentDto } from 'api/accidents/models/AccidentDto';
import { AccidentsSearchCriteria } from 'api/accidents/models/AccidentsSearchCriteria';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import AvatarWithText from 'common/components/avatar/AvatarWithText';
import InputSearch from 'common/components/inputSearch/InputSearch';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import Popover from 'common/components/popover/Popover';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { BLAMING_COLORS, DATE_FORMAT_DEFAUT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Types } from 'screens/vehicle/VehicleScreen';
import { Reducers } from 'store/types';
import { useDebouncedCallback } from 'use-debounce';
import AccidentFiltersScreen, { Filters } from '../AccidentFilters/AccidentFiltersScreen';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import NoImage from 'assets/img/asset-default-image.png';
import styles from './ListAccidentsScreen.module.scss';
import moment from 'moment';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';

export type Props = {
    onClickItem: (item: AccidentDto, isDetails: boolean) => void;
    createNewAccident?: () => void;
    isDriver?: boolean;
    criteriaProp?: AccidentsSearchCriteria;
    isVehicle?: boolean;
    editable: boolean;
    tourReRender?: () => void;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;


const ListAccidentsScreen = ({ onClickItem, createNewAccident, isDriver, criteriaProp, isVehicle, editable, tourReRender, ...props }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const imageCacheKey = useRef(new Date().getTime());

    const { vehicleId, driverId } = useParams<{ vehicleId: string, driverId: string, type: Types, tabId: string, itemType: string, itemId: string }>();
    const [accidents, setAccidents] = useState<AccidentDto[]>([]);

    const [criteria, setCriteria] = useState<AccidentsSearchCriteria>(!criteriaProp ? {
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        vehicleId: vehicleId,
        driverId: isDriver ? driverId : undefined,
        orderColumn: 'a.date',
        orderBy: 'desc'
    } : criteriaProp);
    const [totalItems, setTotalItems] = useState(0);

    const [itemToRemove, setItemToRemove] = useState<AccidentDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const [filters, setFilters] = useState<Filters>({});
    const [filtersTotal, setFiltersTotal] = useState(0);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAccidentsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ACCIDENTS_WRITE']);

    const getData = async () => {
        try {
            const _accidents = await AccidentsService.getList(criteria);

            setTotalItems(_accidents.totalItems);
            setAccidents(_accidents.items);

        } catch (error) {

            Logger.error(LOGGER_LOG_TYPE.ACCIDENTS, `Couldn't get accident list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
        finally{
            tourReRender && tourReRender();
        }
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const renderTableActionCell = (
        row: AccidentDto,
    ) => {
	    const urlDetails = (isVehicle || isDriver) ? `/${isDriver? 'drivers' : 'vehicles'}/details/${isDriver? driverId : vehicleId}/accidents/details/${row.id}` : `accidents/details/${row.id}`;
        const urlEdit = (isVehicle || isDriver) ? `/${isDriver? 'drivers' : 'vehicles'}/details/${isDriver? driverId : vehicleId}/accidents/edit/${row.id}` : `accidents/edit/${row.id}`;


        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={urlDetails}>
                            {t('common.details')}
                        </DropdownItem>
                        { hasAccidentsWritePolicy && editable && <DropdownItem url={urlEdit}>
                            {t('common.edit')}
                        </DropdownItem>}
                        { hasAccidentsWritePolicy && editable && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const tableColumns: ListingTableColumn<AccidentDto>[] = useMemo(() => {
        const cols: ListingTableColumn<AccidentDto>[] = [
            {
                name: t('accidents.list.driver_date'),
                renderCell: row => <div>
                                    <AvatarWithText src={row.driverPhotoUrl} 
                                    size="x2" 
                                    hideAvatar={!row.driverName}><div className={styles.bold}>{row.driverName || '-'}</div></AvatarWithText>
                                <div className={styles.dateResponsibleColumn}>{moment(row.date).format(DATE_FORMAT_DEFAUT) + (row.hour && (' | ' + row.hour))}</div>                    
                        </div>,
                onSearch: onTableFilter,
                searchField: 'a.date'
            },
            {
                name: t('accidents.list.location'),
                renderCell: row => <div>{row.location}</div>,
                onSearch: onTableFilter,
                searchField: 'location',
                hideOn: ['sm', 'md']
            },
            {
                name: t('accidents.list.guilt'),
                renderCell: row =>  <div className={`${styles.statusColumn}`}
                 style={{ color: !!row.guilty ? BLAMING_COLORS.GUILTY : BLAMING_COLORS.NOT_GUILTY }}>
                    <span>{t(`accidents.guilt_types.${row.guilty ? 'guilty' : 'innocent'}` as any)}</span>
                </div>
                ,
                onSearch: onTableFilter,
                searchField: 'guilty'
            },
            {
                name: t('accidents.list.insurance_contract'),
                renderCell: row =>
                    <div>
                        {row.insuranceContractSupplier ? <div>{row.insuranceContractSupplier}, {t('accidents.list.insurance')}</div> : <div>-</div>}
                        {row.insuranceContractNumber ? <div>{t('accidents.list.number_small')} {row.insuranceContractNumber}</div> :<div>-</div> }
                    </div>,
                onSearch: onTableFilter,
                searchField: 'insurance_contract_number',
                hideOn: ['sm', 'md']
            },
            {
                name: t('accidents.list.insurance_assumed_cost'),
                renderCell: row =>  <div>
                    <span>{t(`common.${row.insuranceAssumedCost ? 'yes' : 'no'}` as any)}</span>
                </div>
                ,
                onSearch: onTableFilter,
                searchField: 'guilty'
            },
            {
                name: '',
                width: '60px',
                cellAlignment: 'right',
                preventClick: true,
                renderCell: renderTableActionCell,
                cellStyle: { overflow: 'unset' },
                hideOn: ['sm', 'md', 'lg', 'xl']
            }
        ];

        if (!isVehicle) {
            cols.unshift(
                {
                    renderCell: row =>
                        <div className={styles.imageDescCell}>
                            <div className={styles.contentImage}>
                                <div className={styles.img} style={{ width: '5rem', height: '3rem', backgroundImage: row?.vehiclePhotoUrl ? 'url(' + row?.vehiclePhotoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                            </div>
                        </div>,
                    width: 90,
                    hideOn: ['sm', 'md', 'lg', 'xl']
                },
                {
                    name: t('charges.list.vehicle'),
                    renderCell: row =>
                        <div className={styles.descCell}>
                            <div className={styles.columnPlate}>{row.vehicleRegistrationNumber}</div>
                            <div className={styles.columnBrand}>{row.vehicleBrandName + (' | ') + row.vehicleModelName}</div>
                        </div>,
                    onSearch: onTableFilter,
                    searchField: 'vehicle.registration_number',
                }
            );
        }

        return cols;
    }, []);


    const onPageChange = (page: number) => {
        setCriteria({ ...criteria, page });
    }

    const showRemoveItemDialog = (item: AccidentDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await AccidentsService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete accident`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    useEffect(() => {
        loadFilters();
    }, [criteria]);

    const saveFiltersOnCache = (f:Filters) => {
        setFilters(f);
        localStorage.setItem('ACCIDENTSFILTER', JSON.stringify(f));
    }

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('ACCIDENTSFILTER');
        if(cachedFilters != null && !driverId){onChangeFilters(JSON.parse(cachedFilters),true)}
        else{getData()}
    }

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const onChangeFilters = (f: Filters, isOnLoad?:boolean) => {
        updateTotalFilters(f);
        setFilters(f);
        criteria.driverId = f.driverId;
        criteria.startDate = f.startDate;
        criteria.endDate = f.endDate;
        criteria.guilty = f.guilty;
        criteria.allIn = f.allIn;
        
        if(isOnLoad && f.page){
            criteria.page = f.page
        }else{
            criteria.page = 1;
            f.page = 1;
        }

        saveFiltersOnCache(f);
        getData();
    }

    const updateTotalFilters = (f: Filters) => {
        let total = 0;

        if (f.startDate || f.endDate) {
            total++;
        }
        if (f.allIn) {
            total++;
        }
        if (f.driverId && !isDriver) {
            total++;
        }
        if (f.guilty === false || f.guilty === true) {
            total++;
        }
        //if (f.vehicleId && f.vehicleId.length > 0) {
        //    total++;
        //}

        setFiltersTotal(total);
    }

    return (
        <ScreenTitle title={t('accidents.title')} {...props}>
            <ScreenContainer>
                <div className={styles.tabHeader}>
                    <div>
                        <InputSearch onChangeValue={debounced} placeholder={t('common.search')}></InputSearch>
                    </div>
                    <div className={styles.rowFlex}>
                        {!criteriaProp && <Popover
                            contentContainerClassName={styles.filtersPopoverContainer}
                            containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                            positions={['left']}
                            onClickOutside={() => updateTotalFilters(filters)}
                            content={setIsPopoverOpen =>
                                <AccidentFiltersScreen
                                    isVehicle={isVehicle}
                                    isDriver={isDriver}
                                    filters={filters}
                                    onFilter={f => { setIsPopoverOpen(false); onChangeFilters(f) }}
                                    onChange={f => updateTotalFilters(f)}
                                />
                            }
                        >
                            {(isPopoverOpen, setIsPopoverOpen) => (
                                <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                {filtersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                                </ScreenHeaderButton>
                            )}
                        </Popover>} 
                        {createNewAccident && hasAccidentsWritePolicy && editable && <ScreenHeaderButton icon={AddIcon} onClick={() => createNewAccident()} />}
                    </div>
                </div>

                <div>
                    <ListingTable
                        columns={tableColumns}
                        rows={accidents}
                        onRowClick={row => onClickItem(row, true)}
                        allowHover={true}
                        initialSearch={{ colField: 'a.date', isOrderAsc: false }}
                    />
                    <PaginationWithInfo
                        itemName={t('accidents.list.accidents')}
                        currentPage={criteria.page}
                        pageItems={accidents.length}
                        totalItems={totalItems}
                        onChange={onPageChange} />

                    <QuestionYesNo
                        onNo={onCancelRemove}
                        onYes={onRemove}
                        isVisible={showRemoveModal}
                        message={t('common.messages.remove_record')}/>
                </div>
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default ListAccidentsScreen;
